 .BoxMflActivity .live-dot {
   display: inline-block;
   width: 12px;
   height: 12px;
   background-color: #0dcaf0;
   border-radius: 50%;
   position: relative;
   margin: 0px 10px 2px 10px;
 }

 .BoxMflActivity .live-dot::after {
   content: '';
   position: absolute;
   top: 50%;
   left: 50%;
   width: 14px;
   height: 14px;
   background-color: rgba(13, 202, 240, 0.7);
   border-radius: 50%;
   transform: translate(-60%, -60%);
   animation: pulse 1.5s infinite;
 }

 @keyframes pulse {
   0% {
     transform: translate(-50%, -50%) scale(1);
     opacity: 1;
   }

   100% {
     transform: translate(-50%, -50%) scale(2);
     opacity: 0;
   }
 }

 .BoxMflActivity .ItemCardSale {
   opacity: 0;
   animation: CardSaleFadeIn 1s ease-in forwards;
 }

 @keyframes CardSaleFadeIn {
   0% {
     opacity: 0;
   }

   100% {
     opacity: 1;
   }
 }