#MenuPageUser {
  background-color: #16191C;
  box-shadow: 0px 15px 15px -5px rgba(22, 25, 28, 0.4);
}

#MenuPageUser .nav-link {
  -webkit-transition: font-size .5s;
  -moz-transition: font-size .5s;
  -o-transition: font-size .5s;
  transition: font-size .5s;
}

#MenuPageUser .nav-link:hover {
  color: #0dcaf0;
}

#MenuPageUser .active {
  font-size: 28px !important;
}

#MenuPageUser .active:hover {
  color: white !important;
}

#MenuPageUser .nav-link:hover {
  color: #0dcaf0;
}

#MenuPageUser .nav-user {
  background-color: #adb5bd;
  color: #16191C !important;
  font-size: 28px !important;
  cursor: default !important;
}

#MenuPageUser .nav-user span {
  display: inline !important;
}

#MenuPageUser .LoadingSquare {
  width: 100px !important;
  height: 22px !important;
}