#PageHome .main-view {
  font-family: Impact, sans-serif !important;
}

@media (max-width: 992px) {
  #PageHome .main-view {
    height: calc(100vh - 100px);
  }
}


#PageHome .nav-link:hover {
  color: #0dcaf0 !important;
}

#PageHome .media-mfl {
  position: absolute;
  top: 3%;
  right: 8%;
}

#PageHome .media-x {
  position: absolute;
  top: 13%;
  right: 4%;
}

#PageHome .media-discord {
  position: absolute;
  position: absolute;
  top: 23%;
  right: -2%;
}

#PageHome .media-mfl:hover,
#PageHome .media-x:hover,
#PageHome .media-discord:hover {
  box-shadow: 0 8px 15px rgba(13, 202, 240, 0.3);
  transform: translateY(-3px);
}

#PageHome .searchBar {
  background-color: #0B0C0D;
  animation: SearchBarSlideIn 1s cubic-bezier(0.15, 1, 0.2, 1) forwards;
  display: flex;
  align-items: center;
}

#PageHome .searchBar input {
  opacity: 0;
  animation: SearchInputFadeIn 0.5s ease-in forwards;
  animation-delay: .5s;
}

@keyframes SearchBarSlideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes SearchInputFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}