#MenuPageTools {
  background-color: #16191C;
  box-shadow: 0px 15px 15px -5px rgba(22, 25, 28, 0.4);
}

#MenuPageTools .nav-link {
  -webkit-transition: font-size .5s;
  -moz-transition: font-size .5s;
  -o-transition: font-size .5s;
  transition: font-size .5s;
}

#MenuPageTools .nav-link:hover {
  color: #0dcaf0;
}

#MenuPageTools .active {
  font-size: 28px !important;
}

#MenuPageTools .active:hover {
  color: white !important;
}

#MenuPageTools .nav-link:hover {
  color: #0dcaf0;
}