
.LoadingSquare {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #000 !important;
  background: linear-gradient(to right, #6c757d 8%, #adb5bd 18%, #6c757d 33%);
  background-size: 800px 104px;
  opacity: .2;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}
